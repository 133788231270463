import React from "react";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

const breadCrumbLevels = {
  Home: "/",
  Datenschutz: "de/datenschutz"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/privacy-policy"
);

const Datenschutz = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Datenschutz"
        description="Erläuterung zur Datenerhebung und zum Zweck der Verwendung. Außerdem relevante Informationen zu personenbezogenen Daten und Diensten von Drittanbietern."
        lang="de"
        alternateLangs={alternateLangs}
      />
      <MainContent article >
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

        <H as="h1">Datenschutzerklärung</H>
        <p>
          Ich möchten Ihnen nachfolgend erläutern, welche Daten ich wann und zu welchem Zweck erhebe, verarbeite und
          nutze. Hierbei soll Ihnen erläutert werden, wie meine angebotenen Dienste arbeiten und wie hierbei der
          Schutz Ihrer personenbezogenen Daten gewährleistet wird.
        </p>
        <p>
          Ich erhebe, verarbeite und nutze nur personenbezogene Daten, soweit Sie darin eingewilligt haben oder ein
          Gesetz dies erlaubt.
        </p>
        <p>
          Diese Datenschutzerklärung kann jederzeit unter der URL https://matthiaskupperschmidt.com/de/datenschutz
          abgerufen, abgespeichert und ausgedruckt werden.
        </p>
        <H as="h2">Name und Anschrift des Verantwortlichen</H>
        <p>Verantwortliche Stelle im Sinne der Datenschutzgesetzes ist:</p>
        <p>Matthias Kupperschmidt</p>
        <p>Frederikssundsvej 22</p>
        <p>2400 Kopenhagen, Dänemark</p>
        <p>
          Bei allgemeinen Fragen oder Anregungen an mich zum Thema Datenschutz können Sie sich jederzeit per E-Mail an
          legal@matthiaskupperschmidt.com mit mir in Verbindung setzen.
        </p>
        <H as="h2">Name und Anschrift des Datenschutzbeauftragten</H>
        <p>Matthias Kupperschmidt</p>
        <p>Frederikssundsvej 22</p>
        <p>2400 Kopenhagen, Dänemark</p>
        <p>E-Mail: legal@matthiaskupperschmidt.com</p>
        <H as="h2">Allgemeines zur Datenverarbeitung</H>
        <p>Umfang der Verarbeitung personenbezogener Daten</p>
        <p>
          Ich verarbeiten personenbezogene Daten meiner Nutzer grundsätzlich nur, soweit dies zur Bereitstellung einer
          funktionsfähigen Website sowie meiner Inhalte und Leistungen erforderlich ist. Die Verarbeitung
          personenbezogener Daten meiner Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme
          gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht
          möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.
        </p>
        <H as="h2">Rechtsgrundlage für die Verarbeitung personenbezogener Daten</H>

        <p>
          Soweit ich für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person
          einhole, dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
        </p>
        <p>
          Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrags, dessen Vertragspartei
          die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt
          auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.
        </p>
        <p>
          Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich
          ist, die meinem Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.
        </p>
        <p>
          Ist die Verarbeitung zur Wahrung eines berechtigten Interesses meines Unternehmens oder eines Dritten
          erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte
          Interesse nicht, so dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.
        </p>
        <H as="h2">Datenlöschung und Speicherdauer</H>
        <p>
          Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der
          Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den europäischen oder
          nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der
          Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn
          eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine
          Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung
          besteht.
        </p>
        <p>Arten der verarbeiteten Daten:</p>
        <p>– Bestandsdaten (z.B., Namen, Adressen).</p>
        <p>– Kontaktdaten (z.B., E-Mail, Telefonnummern).</p>
        <p>– Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).</p>
        <p>– Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).</p>
        <p>– Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).</p>
        <H as="h2">Kategorien betroffener Personen</H>
        <p>
          Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichne ich die betroffenen Personen zusammenfassend
          auch als „Nutzer“).
        </p>
        <H as="h2">Zweck der Verarbeitung</H>
        <p>– Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte.</p>
        <p>– Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.</p>
        <p>– Sicherheitsmaßnahmen.</p>
        <p>– Reichweitenmessung/Marketing</p>
        <H as="h2">Verwendete Begrifflichkeiten</H>
        <p>
          „Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare
          natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche
          Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
          zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren
          besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen,
          psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.
        </p>
        <p>
          „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder jede solche
          Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch
          jeden Umgang mit Daten.
        </p>
        <p>
          Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle,
          die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen
          Daten entscheidet, bezeichnet.
        </p>

        <H as="h2">Maßgebliche Rechtsgrundlagen</H>
        <p>
          Nach Maßgabe des Art. 13 DSGVO teile ich Ihnen die Rechtsgrundlagen meiner Datenverarbeitungen mit. Sofern
          die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die Rechtsgrundlage für
          die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die
          Verarbeitung zur Erfüllung meiner Leistungen und Durchführung vertraglicher Maßnahmen sowie Beantwortung von
          Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung meiner
          rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die Rechtsgrundlage für die Verarbeitung zur
          Wahrung meiner berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO.
        </p>
        <H as="h2">Zusammenarbeit mit Auftragsverarbeitern und Dritten</H>
        <p>
          Sofern ich im Rahmen meiner Verarbeitung Daten gegenüber anderen Personen und Unternehmen
          (Auftragsverarbeitern oder Dritten) offenbare, sie an diese übermittele oder ihnen sonst Zugriff auf die
          Daten gewähre, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der
          Daten an Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO zur Vertragserfüllung
          erforderlich ist), Sie eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage
          meiner berechtigten Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc.).
        </p>
        <p>
          Sofern ich Dritte mit der Verarbeitung von Daten auf Grundlage eines sog. „Auftragsverarbeitungsvertrages“
          beauftrage, geschieht dies auf Grundlage des Art. 28 DSGVO.
        </p>
        <H as="h2">Übermittlungen in Drittländer</H>
        <p>
          Sofern ich Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU) oder des Europäischen
          Wirtschaftsraums (EWR)) verarbeite oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder
          Offenlegung, bzw. Übermittlung von Daten an Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung meiner
          (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung
          oder auf Grundlage meiner berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder vertraglicher
          Erlaubnisse, verarbeiten oder lasse ich die Daten in einem Drittland nur beim Vorliegen der besonderen
          Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf Grundlage
          besonderer Garantien, wie der offiziell anerkannten Feststellung eines der EU entsprechenden
          Datenschutzniveaus (z.B. für die USA durch das „Privacy Shield“) oder Beachtung offiziell anerkannter
          spezieller vertraglicher Verpflichtungen (so genannte „Standardvertragsklauseln“).
        </p>

        <H as="h2">Rechte der betroffenen Personen</H>
        <p>
          Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
          Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend Art. 15 DSGVO.
        </p>
        <p>
          Sie haben entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung der Sie betreffenden Daten oder die
          Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
        </p>
        <p>
          Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass betreffende Daten unverzüglich
          gelöscht werden, bzw. alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung der
          Daten zu verlangen.
        </p>
        <p>
          Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie mir bereitgestellt haben nach
          Maßgabe des Art. 20 DSGVO zu erhalten und deren Übermittlung an andere Verantwortliche zu fordern.
        </p>
        <p>
          Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde
          einzureichen.
        </p>
        <p>Datatilsynet</p>
        <p>Borgergade 28, 5</p>
        <p>1300 Kopenhagen K</p>
        <p>Telefon: +45 33 19 32 00, E-Mail: dt@datatilsynet.dk</p>
        <H as="h2">Widerrufsrecht</H>
        <p>
          Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu
          widerrufen
        </p>
        <H as="h2">Widerspruchsrecht</H>
        <p>
          Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des Art. 21 DSGVO jederzeit
          widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung
          erfolgen.
        </p>
        <H as="h2">Cookies und Widerspruchsrecht bei Direktwerbung</H>
        <p>
          Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer gespeichert werden. Innerhalb
          der Cookies können unterschiedliche Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu
          einem Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während oder auch nach seinem Besuch
          innerhalb eines Onlineangebotes zu speichern. Als temporäre Cookies, bzw. „Session-Cookies“, werden Cookies
          bezeichnet, die gelöscht werden, nachdem ein Nutzer ein Onlineangebot verlässt und seinen Browser schließt.
          In einem solchen Cookie kann z.B. ein Login-Staus gespeichert werden. Als „permanent“ oder „persistent“
          werden Cookies bezeichnet, die auch nach dem Schließen des Browsers gespeichert bleiben. So kann z.B. der
          Login-Status gespeichert werden, wenn die Nutzer diese nach mehreren Tagen aufsuchen. Ebenso können in einem
          solchen Cookie die Interessen der Nutzer gespeichert werden, die für Reichweitenmessung oder Marketingzwecke
          verwendet werden. Als „Third-Party-Cookie“ werden Cookies bezeichnet, die von anderen Anbietern als dem
          Verantwortlichen, der das Onlineangebot betreibt, angeboten werden (andernfalls, wenn es nur dessen Cookies
          sind spricht man von „First-Party Cookies“).
        </p>
        <p>
          Ich kann temporäre und permanente Cookies einsetzen und kläre hierüber im Rahmen meiner Datenschutzerklärung
          auf.
        </p>
        <p>
          Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die
          entsprechende Option in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies können
          in den Systemeinstellungen des Browsers gelöscht werden. Der Ausschluss von Cookies kann zu
          Funktionseinschränkungen dieses Onlineangebotes führen.
        </p>
        <p>
          Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann
          bei einer Vielzahl der Dienste, vor allem im Fall des Trackings, über die US-amerikanische Seite
          http://www.aboutads.info/choices/ oder die EU-Seite http://www.youronlinechoices.com/ erklärt werden. Des
          Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des Browsers
          erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes
          genutzt werden können.
        </p>
        <p>
          Beim Aufruf meiner Webseite werden die Nutzer durch einen Infobanner über die Verwendung von Cookies zu
          Analysezwecken informiert und auf diese Datenschutzerklärung verwiesen. Es erfolgt in diesem Zusammenhang
          auch ein Hinweis darauf, wie die Speicherung von Cookies in den Browsereinstellungen unterbunden werden
          kann. Diese sogenannte “Cookie-Weiche” dient dazu den wunsch des Nutzer, welche Cookies gesetzt werden
          sollen, zu speichern. Zu diesem Zweck wird ein eigener Cookie gespeichert. Wenn Sie Ihre Cookies löschen,
          sollten Sie diesen Cookie nicht löschen, da ansonsten die Cookie-Weiche Ihre Präferenzen nicht
          wiedererkennen kann.
        </p>
        <H as="h2">Löschung von Daten</H>
        <p>
          Die von mir verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO gelöscht oder in ihrer
          Verarbeitung eingeschränkt. Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben,
          werden die bei mir gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich
          sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten nicht
          gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren
          Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt
          z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen.
        </p>
        <p>
          Nach gesetzlichen Vorgaben in Deutschland erfolgt die Aufbewahrung insbesondere für 6 Jahre gemäß § 257 Abs.
          1 HGB (Handelsbücher, Inventare, Eröffnungsbilanzen, Jahresabschlüsse, Handelsbriefe, Buchungsbelege, etc.)
          sowie für 10 Jahre gemäß § 147 Abs. 1 AO (Bücher, Aufzeichnungen, Lageberichte, Buchungsbelege, Handels- und
          Geschäftsbriefe, Für Besteuerung relevante Unterlagen, etc.).
        </p>
        <H as="h2">Geschäftsbezogene Verarbeitung</H>
        <p>Zusätzlich verarbeite ich</p>
        <p>– Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit, Kundenkategorie).</p>
        <p>– Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie)</p>
        <p>
          von meinen Kunden, Interessenten und Geschäftspartner zwecks Erbringung vertraglicher Leistungen, Service
          und Kundenpflege, Marketing, Werbung und Marktforschung.
        </p>
        <p>Hosting</p>
        <p>
          Die von mir in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden
          Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
          Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen, die ich zum Zwecke des Betriebs
          dieses Onlineangebotes einsetze.
        </p>
        <p>
          Hierbei verarbeite ich, bzw. mein Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten,
          Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses Onlineangebotes
          auf Grundlage meiner berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung dieses
          Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss
          Auftragsverarbeitungsvertrag).
        </p>
        <H as="h2">Erhebung von Zugriffsdaten und Logfiles</H>
        <p>
          Ich, bzw. Mein Hostinganbieter, erhebt auf Grundlage meiner berechtigten Interessen im Sinne des Art. 6 Abs.
          1 lit. f. DSGVO Daten über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet (sogenannte
          Server-Logfiles). Zu den Zugriffsdaten gehören Name der abgerufenen Webseite, Datei, Datum und Uhrzeit des
          Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp inkl. Version, das
          Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite), IP-Adresse und der anfragende Provider.
        </p>
        <p>
          Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine Auslieferung der
          Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer der
          Sitzung gespeichert bleiben.
        </p>
        <p>
          Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website sicherzustellen, vor allem zur
          Analyse eine Angriff (DOS-Attacke) und dem Missbrauch bereitgestellter Tools. &nbsp;Zudem dienen mir die
          Daten zur Optimierung der Website und zur Sicherstellung der Sicherheit meiner informationstechnischen
          Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt.
        </p>
        <p>
          Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich
          sind. &nbsp;Dies ist in der Regel nach 30 Tagen der Fall.
        </p>
        <p>
          Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles ist für den
          Betrieb der Internetseite zwingend erforderlich. Es besteht folglich seitens des Nutzers keine
          Widerspruchsmöglichkeit.
        </p>
        <H as="h2">Erbringung vertraglicher Leistungen</H>
        <p>
          Ich verarbeite Bestandsdaten (z.B., Namen und Adressen sowie Kontaktdaten von Nutzern), Vertragsdaten (z.B.,
          in Anspruch genommene Leistungen, Namen von Kontaktpersonen, Zahlungsinformationen) zwecks Erfüllung meiner
          vertraglichen Verpflichtungen und Serviceleistungen gem. Art. 6 Abs. 1 lit b. DSGVO. Die in
          Online-Formularen als verpflichtend gekennzeichneten Eingaben, sind für den Vertragsschluss erforderlich.
        </p>
        <p>
          Im Rahmen der Inanspruchnahme meiner Online-Dienste, speichere ich die IP-Adresse und den Zeitpunkt der
          jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf Grundlage meiner berechtigten Interessen, als auch
          der Nutzer an Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte
          erfolgt grundsätzlich nicht, außer sie ist zur Verfolgung meiner Ansprüche erforderlich oder es besteht
          hierzu eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c DSGVO.
        </p>
        <p>
          Ich verarbeite Nutzungsdaten (z.B., die besuchten Webseiten meines Onlineangebotes, Interesse an meinen
          Produkten) und Inhaltsdaten (z.B., Eingaben im Kontaktformular oder Nutzerprofil) für Werbezwecke in einem
          Nutzerprofil, um den Nutzer z.B. Produkthinweise ausgehend von ihren bisher in Anspruch genommenen
          Leistungen einblenden.
        </p>
        <p>
          Die Löschung der Daten erfolgt nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten, die
          Erforderlichkeit der Aufbewahrung der Daten wird alle drei Jahre überprüft; im Fall der gesetzlichen
          Archivierungspflichten erfolgt die Löschung nach deren Ablauf. Angaben im etwaigen Kundenkonto verbleiben
          bis zu dessen Löschung.
        </p>
        <H as="h2">Administration, Finanzbuchhaltung, Büroorganisation, Kontaktverwaltung</H>
        <p>
          Ich verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie Organisation meines Betriebs,
          Finanzbuchhaltung und Befolgung der gesetzlichen Pflichten, wie z.B. der Archivierung. Hierbei verarbeite
          ich dieselben Daten, die ich im Rahmen der Erbringung meiner vertraglichen Leistungen verarbeite. Die
          Verarbeitungsgrundlagen sind Art. 6 Abs. 1 lit. c. DSGVO, Art. 6 Abs. 1 lit. f. DSGVO. Von der Verarbeitung
          sind Kunden, Interessenten, Geschäftspartner und Website-Besucher betroffen. Der Zweck und mein Interesse an
          der Verarbeitung liegt in der Administration, Finanzbuchhaltung, Büroorganisation, Archivierung von Daten,
          also Aufgaben die der Aufrechterhaltung meiner Geschäftstätigkeiten, Wahrnehmung meiner Aufgaben und
          Erbringung meiner Leistungen dienen. Die Löschung der Daten im Hinblick auf vertragliche Leistungen und die
          vertragliche Kommunikation entspricht den, bei diesen Verarbeitungstätigkeiten genannten Angaben.
        </p>
        <p>
          Ich offenbare oder übermittele hierbei Daten an die Finanzverwaltung, Berater, wie z.B., Steuerberater oder
          Wirtschaftsprüfer sowie weitere Gebührenstellen und Zahlungsdienstleister.
        </p>
        <p>
          Ferner speichere ich auf Grundlage meiner betriebswirtschaftlichen Interessen Angaben zu Lieferanten,
          Veranstaltern und sonstigen Geschäftspartnern, z.B. zwecks späterer Kontaktaufnahme. Diese mehrheitlich
          unternehmensbezogenen Daten, speichere ich grundsätzlich dauerhaft.
        </p>
        <H as="h2">Betriebswirtschaftliche Analysen und Marktforschung</H>
        <p>
          Um mein Geschäft wirtschaftlich zu betreiben, Markttendenzen, Kunden- und Nutzerwünsche erkennen zu können,
          analysiere ich die mir vorliegenden Daten zu Geschäftsvorgängen, Verträgen, Anfragen, etc. Ich verarbeite
          dabei Bestandsdaten, Kommunikationsdaten, Vertragsdaten, Zahlungsdaten, Nutzungsdaten, Metadaten auf
          Grundlage des Art. 6 Abs. 1 lit. f. DSGVO, wobei zu den betroffenen Personen Kunden, Interessenten,
          Geschäftspartner, Besucher und Nutzer des Onlineangebotes gehören.
        </p>
        <p>
          Die Analysen erfolgen zum Zweck betriebswirtschaftlicher Auswertungen, des Marketings und der
          Marktforschung. Dabei kann ich die Profile der registrierten Nutzer mit Angaben z.B. zu deren Kaufvorgängen
          berücksichtigen. Die Analysen dienen mir zur Steigerung der Nutzerfreundlichkeit, der Optimierung meines
          Angebotes und der Betriebswirtschaftlichkeit. Die Analysen dienen alleine mir und werden nicht extern
          offenbart, sofern es sich nicht um anonyme Analysen mit zusammengefassten Werten handelt.
        </p>
        <p>
          Sofern diese Analysen oder Profile personenbezogen sind, werden sie mit Kündigung der Nutzer gelöscht oder
          anonymisiert, sonst nach zwei Jahren ab Vertrags-Kündigung. Im Übrigen werden die
          gesamtbetriebswirtschaftlichen Analysen und allgemeine Tendenzbestimmungen nach Möglichkeit anonym erstellt.
        </p>
        <H as="h2">Registrierfunktion</H>
        <p>
          Nutzer können optional im Blog ein Nutzerkonto anlegen. Im Rahmen der Registrierung werden die
          erforderlichen Pflichtangaben den Nutzern mitgeteilt. Die im Rahmen der Registrierung eingegebenen Daten
          werden für die Zwecke der Nutzung des Angebotes verwendet. Die Nutzer können über angebots- oder
          registrierungsrelevante Informationen, wie Änderungen des Angebotsumfangs oder technische Umstände per
          E-Mail informiert werden. Wenn Nutzer ihr Nutzerkonto gekündigt haben, werden deren Daten im Hinblick auf
          das Nutzerkonto gelöscht, vorbehaltlich deren Aufbewahrung ist aus handels- oder steuerrechtlichen Gründen
          entspr. Art. 6 Abs. 1 lit. c DSGVO notwendig. Es obliegt den Nutzern, ihre Daten bei erfolgter Kündigung vor
          dem Vertragsende zu sichern. Ich bin berechtigt, sämtliche während der Vertragsdauer gespeicherten Daten des
          Nutzers unwiederbringlich zu löschen.
        </p>
        <p>
          Im Rahmen der Inanspruchnahme meiner Registrierungs- und Anmeldefunktionen sowie der Nutzung der
          Nutzerkontos, speichere ich die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung
          erfolgt auf Grundlage meiner berechtigten Interessen, als auch der Nutzer an Schutz vor Missbrauch und
          sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, außer sie
          ist zur Verfolgung meiner Ansprüche erforderlich oder es besteht hierzu eine gesetzliche Verpflichtung gem.
          Art. 6 Abs. 1 lit. c DSGVO.
        </p>
        <H as="h2">Kontaktaufnahme</H>
        <p>
          Bei der Kontaktaufnahme mit mir (z.B. per Kontaktformular, E-Mail oder via sozialer Medien) werden die
          Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b) DSGVO
          verarbeitet. Die Angaben der Nutzer können in einem Customer-Relationship-Management System („CRM System“)
          oder vergleichbarer Anfragenorganisation gespeichert werden.
        </p>
        <p>
          Ich lösche die Anfragen, sofern diese nicht mehr erforderlich sind. Ich überprüfe die Erforderlichkeit alle
          zwei Jahre; Ferner gelten die gesetzlichen Archivierungspflichten.
        </p>
        <H as="h2">Kommentare und Beiträge</H>
        <p>
          Wenn Nutzer Kommentare oder sonstige Beiträge hinterlassen, werden ihre IP-Adressen auf Grundlage meiner
          berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO &nbsp;gespeichert. Das erfolgt zu meiner
          Sicherheit, falls jemand in Kommentaren und Beiträgen widerrechtliche Inhalte hinterlässt (Beleidigungen,
          verbotene politische Propaganda, etc.). In diesem Fall kann ich selbst für den Kommentar oder Beitrag
          belangt werden und bin daher an der Identität des Verfassers interessiert.
        </p>
        <H as="h2">Kommentarabonnements</H>
        <p>
          Die Nachfolgekommentare können durch Nutzer mit deren Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO abonniert
          werden. Die Nutzer erhalten eine Bestätigungsemail, um zu überprüfen, ob sie der Inhaber der eingegebenen
          Emailadresse sind. Nutzer können laufende Kommentarabonnements jederzeit abbestellen. Die Bestätigungsemail
          wird Hinweise zu den Widerrufsmöglichkeiten enthalten.
        </p>
        <p>
          Nutzer können gerne Pseudonyme nutzen, oder auf die Eingabe des Namens oder der Emailadresse verzichten. Sie
          können die Übertragung der Daten komplett verhindern, indem Sie mein Kommentarsystem nicht nutzen. Das wäre
          schade, aber leider sehe ich sonst keine Alternativen, die ebenso effektiv arbeiten.
        </p>
        <H as="h2">Newsletter/Infomail/Automatisierte Email-Kommunikation</H>
        <p>
          Mit den nachfolgenden Hinweisen informiere ich Sie über die Inhalte meines Newsletters/Infomail sowie das
          Anmelde-, Versand- und das statistische Auswertungsverfahren sowie Ihre Widerspruchsrechte auf. Indem Sie
          meinen Newsletter/Infomail abonnieren, erklären Sie sich mit dem Empfang und den beschriebenen Verfahren
          einverstanden.
        </p>
        <p>
          Inhalt des Newsletters/Infomail: ich versende Newsletter, E-Mails und weitere elektronische
          Benachrichtigungen mit werblichen Informationen (nachfolgend „Newsletter/Infomail“) nur mit der Einwilligung
          der Empfänger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung zum Newsletter dessen
          Inhalte konkret umschrieben werden, sind sie für die Einwilligung der Nutzer maßgeblich. Im Übrigen
          enthalten meine Newsletter/Infomail Informationen zu meinen Leistungen und mir.
        </p>
        <p>
          Double-Opt-In und Protokollierung: Die Anmeldung zu meinem Newsletter/Infomail erfolgt in einem sog.
          Double-Opt-In-Verfahren. D.h. Sie erhalten nach der Anmeldung eine E-Mail, in der Sie um die Bestätigung
          Ihrer Anmeldung gebeten werden. Diese Bestätigung ist notwendig, damit sich niemand mit fremden
          E-Mailadressen anmelden kann. Die Anmeldungen zum Newsletter werden protokolliert, um den Anmeldeprozess
          entsprechend den rechtlichen Anforderungen nachweisen zu können. Hierzu gehört die Speicherung des Anmelde-
          und des Bestätigungszeitpunkts, als auch der IP-Adresse. Ebenso werden die Änderungen Ihrer bei dem
          Versanddienstleister gespeicherten Daten protokolliert.
        </p>
        <p>
          Anmeldedaten: Um sich für den Newsletter/Infomail anzumelden, reicht es aus, wenn Sie Ihre E-Mailadresse
          angeben. Optional bitten ich Sie einen Namen, zwecks persönlicher Ansprache im Newsletter anzugeben.
        </p>
        <p>
          Deutschland: Der Versand des Newsletters und die mit ihm verbundene Erfolgsmessung erfolgt auf Grundlage
          einer Einwilligung der Empfänger gem. Art. 6 Abs. 1 lit. a, Art. 7 DSGVO i.V.m § 7 Abs. 2 Nr. 3 UWG bzw. auf
          Grundlage der gesetzlichen Erlaubnis gem. § 7 Abs. 3 UWG.
        </p>
        <p>
          Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage meiner berechtigten Interessen gem. Art. 6
          Abs. 1 lit. f DSGVO. Mein Interesse richtet sich auf den Einsatz eines nutzerfreundlichen sowie sicheren
          Newsletter/Infomail-Systems, das sowohl meinen geschäftlichen Interessen dient, als auch den Erwartungen der
          Nutzer entspricht und mir ferner den Nachweis von Einwilligungen erlaubt.
        </p>
        <p>
          Kündigung/Widerruf – Sie können den Empfang meines Newsletters/Infomail jederzeit kündigen, d.h. Ihre
          Einwilligungen widerrufen. Einen Link zur Kündigung des Newsletters/Infomail finden Sie am Ende eines jeden
          Newsletters. Ich kann die ausgetragenen E-Mailadressen bis zu drei Jahren auf Grundlage meiner berechtigten
          Interessen speichern bevor ich sie für Zwecke des Newsletter/Infomail-Versandes lösche, um eine ehemals
          gegebene Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten wird auf den Zweck einer möglichen
          Abwehr von Ansprüchen beschränkt. Ein individueller Löschungsantrag ist jederzeit möglich, sofern zugleich
          das ehemalige Bestehen einer Einwilligung bestätigt wird.
        </p>
        <H as="h2">Newsletter – Versanddienstleister</H>
        <p>
          Der Versand der Newsletter erfolgt mittels des Versanddienstleisters Mailchimp. Der Versanddienstleister
          wird auf Grundlage meiner berechtigten Interessen gem. Art. 6 Abs. 1 lit. f DSGVO und eines
          Auftragsverarbeitungsvertrages gem. Art. 28 Abs. 3 S. 1 DSGVO eingesetzt.
        </p>
        <p>
          Der Versanddienstleister kann die Daten der Empfänger in pseudonymer Form, d.h. ohne Zuordnung zu einem
          Nutzer, zur Optimierung oder Verbesserung der eigenen Services nutzen, z.B. zur technischen Optimierung des
          Versandes und der Darstellung der Newsletter oder für statistische Zwecke verwenden. Der
          Versanddienstleister nutzt die Daten meiner Newsletterempfänger jedoch nicht, um diese selbst anzuschreiben
          oder um die Daten an Dritte weiterzugeben.
        </p>
        <H as="h2">Newsletter – Erfolgsmessung</H>
        <p>
          Die Newsletter enthalten einen sog. „Pixel“, d.h. eine pixelgroße Datei, die beim Öffnen des Newsletters von
          meinem Server, bzw. sofern ich einen Versanddienstleister einsetze, von dessen Server abgerufen wird. Im
          Rahmen dieses Abrufs werden zunächst technische Informationen, wie Informationen zum Browser und Ihrem
          System, als auch Ihre IP-Adresse und Zeitpunkt des Abrufs erhoben.
        </p>
        <p>
          Diese Informationen werden zur technischen Verbesserung der Services anhand der technischen Daten oder der
          Zielgruppen und ihres Leseverhaltens anhand derer Abruforte (die mit Hilfe der IP-Adresse bestimmbar sind)
          oder der Zugriffszeiten genutzt. Zu den statistischen Erhebungen gehört ebenfalls die Feststellung, ob die
          Newsletter geöffnet werden, wann sie geöffnet werden und welche Links geklickt werden. Diese Informationen
          können aus technischen Gründen zwar den einzelnen Newsletterempfängern zugeordnet werden. Es ist jedoch
          weder mein Bestreben, noch, sofern eingesetzt, das des Versanddienstleisters, einzelne Nutzer zu beobachten.
          Die Auswertungen dienen mir viel mehr dazu, die Lesegewohnheiten meiner Nutzer zu erkennen und meine Inhalte
          auf sie anzupassen oder unterschiedliche Inhalte entsprechend den Interessen meiner Nutzer zu versenden.
        </p>
        <H as="h2">Google Analytics</H>
        <p>
          Ich setze auf Grundlage meiner berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und
          wirtschaftlichem Betrieb meines Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Google Analytics,
          einen Webanalysedienst der Google LLC („Google“) ein. Google verwendet Cookies. Die durch das Cookie
          erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an einen
          Server von Google in den USA übertragen und dort gespeichert.
        </p>
        <p>
          Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das
          europäische Datenschutzrecht einzuhalten
          (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active).
        </p>
        <p>
          Google wird diese Informationen in meinem Auftrag benutzen, um die Nutzung meines Onlineangebotes durch die
          Nutzer auszuwerten, um Reports über die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen und
          um weitere, mit der Nutzung dieses Onlineangebotes und der Internetnutzung verbundene Dienstleistungen, mir
          gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer
          erstellt werden.
        </p>
        <p>
          Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten von Google
          zusammengeführt. Die Nutzer können die Speicherung der Cookies durch eine entsprechende Einstellung ihrer
          Browser-Software verhindern; die Nutzer können darüber hinaus die Erfassung der durch das Cookie erzeugten
          und auf ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch
          Google verhindern, indem sie das unter folgendem Link verfügbare Browser-Plugin herunterladen und
          installieren: http://tools.google.com/dlpage/gaoptout?hl=de.
        </p>
        <p>
          Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten erfahren
          Sie auf den Webseiten von Google: https://www.google.com/intl/de/policies/privacy/partners („Datennutzung
          durch Google bei Ihrer Nutzung von Websites oder Apps meiner Partner“), https://adssettings.google.de
          („Datennutzung zu Werbezwecken“), („Informationen verwalten, die Google verwendet, um Ihnen Werbung
          einzublenden“).
        </p>
        <H as="h2">Google-Re/Marketing-Services</H>
        <p>
          Ich nutze auf Grundlage meiner berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und
          wirtschaftlichem Betrieb meines Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) die Marketing- und
          Remarketing-Dienste (kurz „Google-Marketing-Services”) der Google Ireland Limited, Gordon House, Barrow
          Street, Dublin 4, Irland, („Google“).
        </p>
        <p>
          Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das
          europäische Datenschutzrecht einzuhalten
          (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active).
        </p>
        <p>
          Die Google-Marketing-Services erlauben mir Werbeanzeigen für und auf meiner Website gezielter anzuzeigen, um
          Nutzern nur Anzeigen zu präsentieren, die potentiell deren Interessen entsprechen. Falls einem Nutzer z.B.
          Anzeigen für Produkte angezeigt werden, für die er sich auf anderen Webseiten interessiert hat, spricht man
          hierbei vom „Remarketing“. Zu diesen Zwecken wird bei Aufruf meiner und anderer Webseiten, auf denen
          Google-Marketing-Services aktiv sind, unmittelbar durch Google ein Code von Google ausgeführt und es werden
          sog. (Re)marketing-Tags (unsichtbare Grafiken oder Code, auch als „Pixel“ bezeichnet) in die Webseite
          eingebunden. Mit deren Hilfe wird auf dem Gerät der Nutzer ein individuelles Cookie, d.h. eine kleine Datei
          abgespeichert (statt Cookies können auch vergleichbare Technologien verwendet werden). Die Cookies können
          von verschiedenen Domains gesetzt werden, unter anderem von google.com, doubleclick.net, invitemedia.com,
          admeld.com, googlesyndication.com oder googleadservices.com. In dieser Datei wird vermerkt, welche Webseiten
          der Nutzer aufgesucht, für welche Inhalte er sich interessiert und welche Angebote er geklickt hat, ferner
          technische Informationen zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere
          Angaben zur Nutzung des Onlineangebotes. Es wird ebenfalls die IP-Adresse der Nutzer erfasst, wobei ich im
          Rahmen von Google-Analytics mitteile, dass die IP-Adresse innerhalb von Mitgliedstaaten der Europäischen
          Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt und nur in
          Ausnahmefällen ganz an einen Server von Google in den USA übertragen und dort gekürzt wird. Die IP-Adresse
          wird nicht mit Daten des Nutzers innerhalb von anderen Angeboten von Google zusammengeführt. Die vorstehend
          genannten Informationen können seitens Google auch mit solchen Informationen aus anderen Quellen verbunden
          werden. Wenn der Nutzer anschließend andere Webseiten besucht, können ihm entsprechend seiner Interessen die
          auf ihn abgestimmten Anzeigen angezeigt werden.
        </p>
        <p>
          Die Daten der Nutzer werden im Rahmen der Google-Marketing-Services pseudonym verarbeitet. D.h. Google
          speichert und verarbeitet z.B. nicht den Namen oder E-Mailadresse der Nutzer, sondern verarbeitet die
          relevanten Daten Cookie-bezogen innerhalb pseudonymer Nutzer-Profile. D.h. aus der Sicht von Google werden
          die Anzeigen nicht für eine konkret identifizierte Person verwaltet und angezeigt, sondern für den
          Cookie-Inhaber, unabhängig davon wer dieser Cookie-Inhaber ist. Dies gilt nicht, wenn ein Nutzer Google
          ausdrücklich erlaubt hat, die Daten ohne diese Pseudonymisierung zu verarbeiten. Die von
          Google-Marketing-Services über die Nutzer gesammelten Informationen werden an Google übermittelt und auf
          Googles Servern in den USA gespeichert.
        </p>
        <p>
          Zu den von mir eingesetzten Google-Marketing-Services gehört u.a. das Online-Werbeprogramm „Google Ads“. Im
          Fall von Google Ads, erhält jeder Ads-Kunde ein anderes „Conversion-Cookie“. Cookies können somit nicht über
          die Websites von Ads-Kunden nachverfolgt werden. Die mit Hilfe des Cookies eingeholten Informationen dienen
          dazu, Conversion-Statistiken für Ads-Kunden zu erstellen, die sich für Conversion-Tracking entschieden
          haben. Die Ads-Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer
          mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine
          Informationen, mit denen sich Nutzer persönlich identifizieren lassen.
        </p>
        <p>
          Ferner setze ich den „Google Tag Manager“ ein, um die Google Analyse- und Marketing-Dienste in meine Website
          einzubinden und zu verwalten.
        </p>
        <p>
          Weitere Informationen zur Datennutzung zu Marketingzwecken durch Google, erfahren Sie auf der
          Übersichtsseite: https://www.google.com/policies/technologies/ads, die Datenschutzerklärung von Google ist
          unter https://www.google.com/policies/privacy abrufbar.
        </p>
        <p>
          Wenn Sie der interessensbezogenen Werbung durch Google-Marketing-Services widersprechen möchten, können Sie
          die von Google gestellten Einstellungs- und Opt-Out-Möglichkeiten nutzen:
          http://www.google.com/ads/preferences.
        </p>
        <H as="h2">Zielgruppenbildung mit Google Analytics</H>
        <p>
          Ich setze Google Analytics ein, um die durch innerhalb von Werbediensten Googles und seiner Partner
          geschalteten Anzeigen, nur solchen Nutzern anzuzeigen, die auch ein Interesse an meinem Onlineangebot
          gezeigt haben oder die bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder Produkten, die anhand
          der besuchten Webseiten bestimmt werden) aufweisen, die ich an Google übermittele (sog. „Remarketing-“, bzw.
          „Google-Analytics-Audiences“). Mit Hilfe der Remarketing Audiences möchte ich auch sicherstellen, dass meine
          Anzeigen dem potentiellen Interesse der Nutzer entsprechen.
        </p>

        <H as="h2">Facebook-Pixel, Custom Audiences und Facebook-Conversion</H>
        <p>
          Innerhalb meines Onlineangebotes wird aufgrund meiner berechtigten Interessen an Analyse, Optimierung und
          wirtschaftlichem Betrieb meines Onlineangebotes und zu diesen Zwecken das sog. „Facebook-Pixel“ des sozialen
          Netzwerkes Facebook, welches von der Facebook Inc., 1 Hacker Way, Menlo Park, CA 94025, USA, bzw. falls Sie
          in der EU ansässig sind, Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
          betrieben wird („Facebook“), eingesetzt.
        </p>
        <p>
          Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das
          europäische Datenschutzrecht einzuhalten
          (https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active).
        </p>
        <p>
          Mit Hilfe des Facebook-Pixels ist es Facebook zum einen möglich, die Besucher meines Onlineangebotes als
          Zielgruppe für die Darstellung von Anzeigen (sog. „Facebook-Ads“) zu bestimmen. Dementsprechend setze ich
          das Facebook-Pixel ein, um die durch mich geschalteten Facebook-Ads nur solchen Facebook-Nutzern anzuzeigen,
          die auch ein Interesse an meinem Onlineangebot gezeigt haben oder die bestimmte Merkmale (z.B. Interessen an
          bestimmten Themen oder Produkten, die anhand der besuchten Webseiten bestimmt werden) aufweisen, die ich an
          Facebook übermittele (sog. „Custom Audiences“). Mit Hilfe des Facebook-Pixels möchte ich auch sicherstellen,
          dass meine Facebook-Ads dem potentiellen Interesse der Nutzer entsprechen und nicht belästigend wirken. Mit
          Hilfe des Facebook-Pixels kann ich ferner die Wirksamkeit der Facebook-Werbeanzeigen für statistische und
          Marktforschungszwecke nachvollziehen, in dem ich sehe ob Nutzer nach dem Klick auf eine
          Facebook-Werbeanzeige auf meine Website weitergeleitet wurden (sog. „Conversion“).
        </p>
        <p>
          Die Verarbeitung der Daten durch Facebook erfolgt im Rahmen von Facebooks Datenverwendungsrichtlinie.
          Dementsprechend generelle Hinweise zur Darstellung von Facebook-Ads, in der Datenverwendungsrichtlinie von
          Facebook: https://www.facebook.com/policy.php. Spezielle Informationen und Details zum Facebook-Pixel und
          seiner Funktionsweise erhalten Sie im Hilfebereich von Facebook:
          https://www.facebook.com/business/help/651294705016616.
        </p>
        <p>
          Sie können der Erfassung durch den Facebook-Pixel und Verwendung Ihrer Daten zur Darstellung von
          Facebook-Ads widersprechen. Um einzustellen, welche Arten von Werbeanzeigen Ihnen innerhalb von Facebook
          angezeigt werden, können Sie die von Facebook eingerichtete Seite aufrufen und dort die Hinweise zu den
          Einstellungen nutzungsbasierter Werbung befolgen: https://www.facebook.com/settings?tab=ads. Die
          Einstellungen erfolgen plattformunabhängig, d.h. sie werden für alle Geräte, wie Desktopcomputer oder mobile
          Geräte übernommen.
        </p>
        <p>
          Sie können dem Einsatz von Cookies, die der Reichweitenmessung und Werbezwecken dienen, ferner über die
          Deaktivierungsseite der Netzwerkwerbeinitiative (http://optout.networkadvertising.org/) und zusätzlich die
          US-amerikanische Webseite (http://www.aboutads.info/choices) oder die europäische Webseite
          (http://www.youronlinechoices.com/uk/your-ad-choices/) widersprechen.
        </p>
        <H as="h2">Bing ADs</H>
        <p>
          Auf meiner Webseite werden mit Technologien der Bing Ads Daten erhoben und gespeichert, aus denen unter
          Verwendung von Pseudonymen Nutzungsprofile erstellt werden. Hierbei handelt es sich um einen Dienst der
          Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA. Dieser Dienst ermöglicht es uns, die
          Aktivitäten von Nutzern auf meiner Website nachzuverfolgen, wenn diese über Anzeigen von Bing Ads auf meine
          Website gelangt sind. Gelangen Sie über eine solche Anzeige auf meine Website, wird auf Ihren Computer ein
          Cookie gesetzt. Auf meiner Website ist ein Bing UET-Tag integriert. Hierbei handelt es sich um einen Code,
          über den in Verbindung mit dem Cookie einige nicht-personenbezogene Daten über die Nutzung der Website
          gespeichert werden. Dazu gehören unter anderem die Verweildauer auf der Website, welche Bereiche der Website
          abgerufen wurden und über welche Anzeige die Nutzer auf die Website gelangt sind. Informationen zu Ihrer
          Identität werden nicht erfasst.
        </p>
        <p>
          Die erfassten Informationen werden an Server von Microsoft in den USA übertragen und dort für grundsätzlich
          maximal 180 Tage gespeichert. Sie können die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung
          der Website bezogenen Daten sowie die Verarbeitung dieser Daten verhindern, indem Sie das Setzen von Cookies
          deaktivieren. Dadurch kann unter Umständen die Funktionalität der Website eingeschränkt werden.
        </p>
        <p>
          Außerdem kann Microsoft unter Umständen durch so genanntes Cross-Device-Tracking Ihr Nutzungsverhalten über
          mehrere Ihrer elektronischen Geräte hinweg verfolgen und ist dadurch in der Lage personalisierte Werbung auf
          bzw. in Microsoft-Webseiten und –Apps einzublenden. Dieses Verhalten können Sie unter
          http://choice.microsoft.com/de-de/opt-out deaktivieren.
        </p>
        <p>
          Nähere Informationen zu den Analysediensten von Bing finden Sie auf der Website von Bing Ads (
          https://help.bingads.microsoft.com/#apex/3/de/53056/2 ). Nähere Informationen zum Datenschutz bei Microsoft
          und Bing finden Sie in den Datenschutzbestimmungen von Microsoft (
          https://privacy.microsoft.com/de-de/privacystatement).
        </p>
        <H as="h2">Onlinepräsenzen in sozialen Medien</H>
        <p>
          Ich unterhalte Onlinepräsenzen innerhalb sozialer Netzwerke und Plattformen, um mit den dort aktiven Kunden,
          Interessenten und Nutzern kommunizieren und sie dort über meine Leistungen informieren zu können. Beim
          Aufruf der jeweiligen Netzwerke und Plattformen gelten die Geschäftsbedingungen und die
          Datenverarbeitungsrichtlinien der jeweiligen Betreiber.
        </p>
        <p>
          Soweit nicht anders im Rahmen meiner Datenschutzerklärung angegeben, verarbeite ich die Daten der Nutzer,
          sofern diese mit mir innerhalb der sozialen Netzwerke und Plattformen kommunizieren, z.B. Beiträge auf
          meinen Onlinepräsenzen verfassen oder mir Nachrichten zusenden.
        </p>
        <H as="h2">Einbindung von Diensten und Inhalten Dritter</H>
        <p>
          Ich setze innerhalb meines Onlineangebotes auf Grundlage meiner berechtigten Interessen (d.h. Interesse an
          der Analyse, Optimierung und wirtschaftlichem Betrieb meines Onlineangebotes im Sinne des Art. 6 Abs. 1 lit.
          f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern ein, um deren Inhalte und Services, wie z.B.
          Videos oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als “Inhalte”).
        </p>
        <p>
          Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der Nutzer wahrnehmen, da sie
          ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die
          Darstellung dieser Inhalte erforderlich. Ich bemühe mich nur solche Inhalte zu verwenden, deren jeweiliger
          Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwendet. Drittanbieter können ferner so
          genannte Pixel-Tags (unsichtbare Grafiken, auch als „Pixel“ bezeichnet) für statistische oder
          Marketingzwecke verwenden. Durch die „Pixel-Tags“ können Informationen, wie der Besucherverkehr auf den
          Seiten dieser Website ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf dem
          Gerät der Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und
          Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung meines Onlineangebotes
          enthalten, als auch mit solchen Informationen aus anderen Quellen verbunden werden.
        </p>
        <H as="h2">Youtube</H>
        <p>
          ich binde die Videos der Plattform “YouTube” des Anbieters Google Ireland Limited, Gordon House, Barrow
          Street, Dublin 4, Irland, ein. Datenschutzerklärung: https://www.google.com/policies/privacy/, Opt-Out:
          https://adssettings.google.com/authenticated.
        </p>
        <H as="h2">Verwendung von Facebook Social Plugins</H>
        <p>
          Ich nutze auf Grundlage meiner berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und
          wirtschaftlichem Betrieb meines Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Social Plugins
          („Plugins“) des sozialen Netzwerkes facebook.com, welches von der Facebook Ireland Ltd., 4 Grand Canal
          Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird („Facebook“). Die Plugins können
          Interaktionselemente oder Inhalte (z.B. Videos, Grafiken oder Textbeiträge) darstellen und sind an einem der
          Facebook Logos erkennbar (weißes „f“ auf blauer Kachel, den Begriffen „Like“, „Gefällt mir“ oder einem
          „Daumen hoch“-Zeichen) oder sind mit dem Zusatz „Facebook Social Plugin“ gekennzeichnet. Die Liste und das
          Aussehen der Facebook Social Plugins kann hier eingesehen werden:
          https://developers.facebook.com/docs/plugins/.
        </p>
        <p>
          Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das
          europäische Datenschutzrecht einzuhalten
          (https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active).
        </p>
        <p>
          Wenn ein Nutzer eine Funktion dieses Onlineangebotes aufruft, die ein solches Plugin enthält, baut sein
          Gerät eine direkte Verbindung mit den Servern von Facebook auf. Der Inhalt des Plugins wird von Facebook
          direkt an das Gerät des Nutzers übermittelt und von diesem in das Onlineangebot eingebunden. Dabei können
          aus den verarbeiteten Daten Nutzungsprofile der Nutzer erstellt werden. Ich habe daher keinen Einfluss auf
          den Umfang der Daten, die Facebook mit Hilfe dieses Plugins erhebt und informiere die Nutzer daher meinem
          Kenntnisstand entsprechend.
        </p>
        <p>
          Durch die Einbindung der Plugins erhält Facebook die Information, dass ein Nutzer die entsprechende Seite
          des Onlineangebotes aufgerufen hat. Ist der Nutzer bei Facebook eingeloggt, kann Facebook den Besuch seinem
          Facebook-Konto zuordnen. Wenn Nutzer mit den Plugins interagieren, zum Beispiel den Like Button betätigen
          oder einen Kommentar abgeben, wird die entsprechende Information von Ihrem Gerät direkt an Facebook
          übermittelt und dort gespeichert. Falls ein Nutzer kein Mitglied von Facebook ist, besteht trotzdem die
          Möglichkeit, dass Facebook seine IP-Adresse in Erfahrung bringt und speichert. Laut Facebook wird in
          Deutschland nur eine anonymisierte IP-Adresse gespeichert.
        </p>
        <p>
          Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie
          die diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz der Privatsphäre der Nutzer, können
          diese den Datenschutzhinweisen von Facebook entnehmen: https://www.facebook.com/about/privacy/.
        </p>
        <p>
          Wenn ein Nutzer Facebookmitglied ist und nicht möchte, dass Facebook über dieses Onlineangebot Daten über
          ihn sammelt und mit seinen bei Facebook gespeicherten Mitgliedsdaten verknüpft, muss er sich vor der Nutzung
          meines Onlineangebotes bei Facebook ausloggen und seine Cookies löschen. Weitere Einstellungen und
          Widersprüche zur Nutzung von Daten für Werbezwecke, sind innerhalb der Facebook-Profileinstellungen möglich:
          https://www.facebook.com/settings?tab=ads &nbsp;oder über die US-amerikanische Seite
          http://www.aboutads.info/choices/ &nbsp;oder die EU-Seite http://www.youronlinechoices.com/. Die
          Einstellungen erfolgen plattformunabhängig, d.h. sie werden für alle Geräte, wie Desktopcomputer oder mobile
          Geräte übernommen.
        </p>
        <H as="h2">Twitter</H>
        <p>
          Innerhalb meines Onlineangebotes können Funktionen und Inhalte des Dienstes Twitter eingebunden, angeboten
          durch die Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA. Hierzu können z.B.
          Inhalte wie Bilder, Videos oder Texte und Schaltflächen gehören, mit denen Nutzer Ihr Gefallen betreffend
          die Inhalte kundtun, den Verfassern der Inhalte oder meine Beiträge abonnieren können. Sofern die Nutzer
          Mitglieder der Plattform Twitter sind, kann Twitter den Aufruf der o.g. Inhalte und Funktionen den dortigen
          Profilen der Nutzer zuordnen. Twitter ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet
          hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten
          (https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active). Datenschutzerklärung:
          https://twitter.com/de/privacy, Opt-Out: https://twitter.com/personalization.
        </p>
        <H as="h2">LinkedIn</H>
        <p>
          Innerhalb meines Onlineangebotes können Funktionen und Inhalte des Dienstes LinkedIn eingebunden, angeboten
          durch die LinkedIn AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland. Hierzu können z.B. Inhalte wie
          Bilder, Videos oder Texte und Schaltflächen gehören, mit denen Nutzer Ihr Gefallen betreffend die Inhalte
          kundtun, den Verfassern der Inhalte oder meine Beiträge abonnieren können. Sofern die Nutzer Mitglieder der
          Plattform LinkedIn sind, kann LinkedIn den Aufruf der o.g. Inhalte und Funktionen den dortigen Profilen der
          Nutzer zuordnen. Datenschutzerklärung von LinkedIn: https://www.linkedin.com/legal/privacy-policy.. LinkedIn
          ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische
          Datenschutzrecht einzuhalten
          (https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active). Datenschutzerklärung:
          https://twitter.com/de/privacy, Opt-Out:
          https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.
        </p>
        <H as="h2">Xing</H>
        <p>
          Innerhalb meines Onlineangebotes können Funktionen und Inhalte des Dienstes Xing, angeboten durch die XING
          AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland, eingebunden werden. Hierzu können z.B. Inhalte wie
          Bilder, Videos oder Texte und Schaltflächen gehören, mit denen Nutzer Ihr Gefallen betreffend die Inhalte
          kundtun, den Verfassern der Inhalte oder meine Beiträge abonnieren können. Sofern die Nutzer Mitglieder der
          Plattform Xing sind, kann Xing den Aufruf der o.g. Inhalte und Funktionen den dortigen Profilen der Nutzer
          zuordnen. Datenschutzerklärung von Xing: https://www.xing.com/app/share?op=data_protection.
        </p>

        <H as="h2">Datenschutzhinweise im Bewerbungsverfahren</H>
        <p>
          Ich verarbeite die Bewerberdaten nur zum Zweck und im Rahmen des Bewerbungsverfahrens im Einklang mit den
          gesetzlichen Vorgaben. Die Verarbeitung der Bewerberdaten erfolgt zur Erfüllung meiner (vor)vertraglichen
          Verpflichtungen im Rahmen des Bewerbungsverfahrens im Sinne des Art. 6 Abs. 1 lit. b. DSGVO Art. 6 Abs. 1
          lit. f. DSGVO sofern die Datenverarbeitung z.B. im Rahmen von rechtlichen Verfahren für mich erforderlich
          wird (in Deutschland gilt zusätzlich § 26 BDSG).
        </p>

        <p>
          Das Bewerbungsverfahren setzt voraus, dass Bewerber mir die Bewerberdaten mitteilen. Die notwendigen
          Bewerberdaten sind, sofern ich ein Onlineformular anbiete gekennzeichnet, ergeben sich sonst aus den
          Stellenbeschreibungen und grundsätzlich gehören dazu die Angaben zur Person, Post- und Kontaktadressen und
          die zur Bewerbung gehörenden Unterlagen, wie Anschreiben, Lebenslauf und die Zeugnisse. Daneben kann ich
          Bewerber freiwillig zusätzliche Informationen mitteilen.
        </p>
        <p>
          Mit der Übermittlung der Bewerbung an mich, erklären sich die Bewerber mit der Verarbeitung ihrer Daten zu
          Zwecken des Bewerbungsverfahrens entsprechend der in dieser Datenschutzerklärung dargelegten Art und Umfang
          einverstanden.
        </p>
        <p>
          Soweit im Rahmen des Bewerbungsverfahrens freiwillig besondere Kategorien von personenbezogenen Daten im
          Sinne des Art. 9 Abs. 1 DSGVO mitgeteilt werden, erfolgt deren Verarbeitung zusätzlich nach Art. 9 Abs. 2
          lit. b DSGVO (z.B. Gesundheitsdaten, wie z.B. Schwerbehinderteneigenschaft oder ethnische Herkunft). Soweit
          im Rahmen des Bewerbungsverfahrens besondere Kategorien von personenbezogenen Daten im Sinne des Art. 9 Abs.
          1 DSGVO bei Bewerbern angefragt werden, erfolgt deren Verarbeitung zusätzlich nach Art. 9 Abs. 2 lit. a
          DSGVO (z.B. Gesundheitsdaten, wenn diese für die Berufsausübung erforderlich sind).
        </p>
        <p>
          Sofern zur Verfügung gestellt, kann ich Bewerber ihre Bewerbungen mittels eines Onlineformulars auf meiner
          Website übermitteln. Die Daten werden entsprechend dem Stand der Technik verschlüsselt an mich übertragen.
        </p>
        <p>
          Ferner können Bewerber mir ihre Bewerbungen via E-Mail übermitteln. Hierbei bitte ich jedoch zu beachten,
          dass E-Mails grundsätzlich nicht verschlüsselt versendet werden und die Bewerber selbst für die
          Verschlüsselung sorgen müssen. Ich kann daher für den Übertragungsweg der Bewerbung zwischen dem Absender
          und dem Empfang auf meinem Server keine Verantwortung übernehmen und empfehlen daher eher ein
          Online-Formular oder den postalischen Versand zu nutzen. Denn statt der Bewerbung über das Online-Formular
          und E-Mail, steht den Bewerbern weiterhin die Möglichkeit zur Verfügung, mir die Bewerbung auf dem Postweg
          zuzusenden.
        </p>

        <p>
          Die von den Bewerbern zur Verfügung gestellten Daten, können im Fall einer erfolgreichen Bewerbung für die
          Zwecke des Beschäftigungsverhältnisses von mir weiterverarbeitet werden. Andernfalls, sofern die Bewerbung
          auf ein Stellenangebot nicht erfolgreich ist, werden die Daten der Bewerber gelöscht. Die Daten der Bewerber
          werden ebenfalls gelöscht, wenn eine Bewerbung zurückgezogen wird, wozu die Bewerber jederzeit berechtigt
          sind.
        </p>
        <p>
          Die Löschung erfolgt, vorbehaltlich eines berechtigten Widerrufs der Bewerber, nach dem Ablauf eines
          Zeitraums von sechs Monaten, damit ich etwaige Anschlussfragen zu der Bewerbung beantworten und meinen
          Nachweispflichten aus dem Gleichbehandlungsgesetz genügen kann. Rechnungen über etwaige
          Reisekostenerstattung werden entsprechend den steuerrechtlichen Vorgaben archiviert.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);
export default Datenschutz;
